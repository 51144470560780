import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message
} from "semantic-ui-react";
import offeredCourses from "../Server/currentCourses.json";
const MAX_NAME = 30;
const MIN_PASSWORD = 10;


/**
 * Registration component makes fetch call to website with information
 * needed for registration of a new user. 
 * 
 * 
 * Data:
 * {
    "userId": "qd7373",
    "first": "Greg",
    "last": "Bernstein",
    "email": "greg.bernstein@csueastbay.edu",
    "courses": ["CS351", "CS651", "CS671"],
    "password": "Something that will be hashed"
}

  For tutorial on RegEx validation see: http://www.tutorialspark.com/javascript/JavaScript_Regular_Expression_Form_Validation.php
 */
class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.selectedCourses = new Set();
    this.state = {
      userId: "",
      userIdError: null,
      first: "",
      firstError: null,
      last: "",
      lastError: null,
      email: "",
      emailError: null,
      password: "",
      passwordError: null,
      confPassword: "",
      confPasswordError: null,
      coursesError: null,
      formError: false,
      regError: false,
      regSuccess: false,
      regMsg: ""
    };
  }

  registerClick() {
    let that = this;
    console.log("Button pressed");
    // Validate all inputs
    let error1 = this.checkUserId(this.state.userId);
    let error2 = this.checkFirst(this.state.first);
    let error3 = this.checkLast(this.state.last);
    let error4 = this.checkSelectedCourses();
    let error5 = this.checkPassword(this.state.password);
    let error6 = this.checkConfPassword(this.state.confPassword);
    let error7 = this.checkEmail(this.state.email);
    let error =
      error1 || error2 || error3 || error4 || error5 || error6 || error7;
      this.setState({formError: error});
    if (error) {
      console.log("Some error in user inputs!");
      return;
    }
    this.setState({regError: false, regSuccess: false});
    fetch("/register", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: that.state.userId,
        first: that.state.first,
        last: that.state.last,
        email: that.state.email,
        courses: Array.from(that.selectedCourses.values()),
        password: that.state.password,
      }),
    })
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        return response.json();
      })
      .then(function (msg) {
        if (msg.error) {
          // Bad Registration
          that.setState({ regError: true, regMsg: msg.message });
          console.log(msg);
        } else {
          // Good registration
          that.setState({regSuccess: true, regMsg:msg.message})
        }
      });
  }

  firstInput(event) {
    let first = event.target.value;
    this.setState({ first: first });
    this.checkFirst(first);
  }

  checkFirst(first) {
    const regEx = /^[a-zA-Z]+( [a-zA-Z]+)?$/;
    let error =
      first.length < 1 || !regEx.test(first) || first.length > MAX_NAME;
    this.setState({ firstError: error });
    return error;
  }

  lastInput(event) {
    let last = event.target.value;
    this.setState({ last: last });
    this.checkLast(last);
  }

  checkLast(last) {
    const regEx = /^[a-zA-Z]+( [a-zA-Z]+)?$/;
    let error = last.length < 1 || !regEx.test(last) || last.length > MAX_NAME;
    this.setState({ lastError: error });
    return error;
  }

  emailInput(event) {
    let email = event.target.value;
    this.setState({ email: email });
    this.checkEmail(email);
  }

  checkEmail(email) {
    // email regEx from https://www.emailregex.com/
    const regEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = !regEx.test(email) || email.length < 1;
    this.setState({ emailError: error });
    return error;
  }

  userIdInput(event) {
    let userId = event.target.value.toLowerCase();
    this.setState({ userId: userId, userIdError: this.checkUserId(userId) });
    this.checkUserId(userId);
  }

  checkUserId(userId) {
    // Our user id consist of two letters followed by 4 digits
    const regEx = /^[a-z]{2}\d{4}$/;
    let error = !regEx.test(userId);
    this.setState({ userIdError: error });
    return error;
  }

  passInput(event) {
    let password = event.target.value;
    this.setState({ password: password });
    this.checkPassword(password);
  }

  checkPassword(password) {
    let error = password.length < MIN_PASSWORD;
    this.setState({ passwordError: error });
    return error;
  }

  confPassInput(event) {
    let confPassword = event.target.value;
    this.setState({ confPassword: confPassword });
    this.checkConfPassword(confPassword);
  }

  checkConfPassword(confPassword) {
    let error = confPassword !== this.state.password;
    this.setState({ confPasswordError: error });
    return error;
  }

  courseChange(event, data) {
    if (data.checked) {
      this.selectedCourses.add(data.label);
    } else {
      this.selectedCourses.delete(data.label);
    }
    this.checkSelectedCourses();
  }

  checkSelectedCourses() {
    let error = this.selectedCourses.size < 1;
    this.setState({ coursesError: error });
    return error;
  }

  // Renders component based on current state and props
  render() {
    let that = this;
    let courseBoxes = offeredCourses.map(function (course) {
      return (
        <Form.Checkbox
          key={course}
          label={course}
          onChange={that.courseChange.bind(that)}
          error={that.state.coursesError}
        />
      );
    });
    return (
      <Grid style={{ height: "100vh" }} verticalAlign="middle" centered>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            Register for{" "}
            <abbr title="Computer Science Oriented Audience Response System">
              CSOARS
            </abbr>
          </Header>
          <Form size="large" error={this.state.formError} warning={this.state.regError} success={this.state.regSuccess}>
            <Form.Group>
              <Form.Input
                label="Net Id"
                width={4}
                error={this.state.userIdError}
                placeholder="User Id"
                value={this.state.userId}
                onChange={this.userIdInput.bind(this)}
              />
              <Form.Input
                fluid
                width={12}
                label="Email"
                placeholder="email"
                error={this.state.emailError}
                value={this.state.email}
                onChange={this.emailInput.bind(this)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="First Name"
                placeholder="First Name"
                error={this.state.firstError}
                value={this.state.first}
                onChange={this.firstInput.bind(this)}
              />
              <Form.Input
                fluid
                label="Last Name"
                placeholder="Last Name"
                error={this.state.lastError}
                value={this.state.last}
                onChange={this.lastInput.bind(this)}
              />
            </Form.Group>
            <Form.Group inline>
              <label>Select course(s)</label>
              {courseBoxes}
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Password"
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                error={this.state.passwordError}
                value={this.state.password}
                onChange={this.passInput.bind(this)}
              />
              <Form.Input
                fluid
                label="Confirm Password"
                icon="lock"
                iconPosition="left"
                placeholder="Confirm Password"
                type="password"
                error={this.state.confPasswordError}
                value={this.state.confirmPassword}
                onChange={this.confPassInput.bind(this)}
              />
            </Form.Group>
            <Message
              error
              header="Missing or Incorrect Fields"
              content="Please fix missing or incorrect fields and re-submit."
            />
            <Message
              warning
              header="Registration Problem"
              content={this.state.regMsg}
            />
            <Message
              success
              header="Registration Complete"
              content={this.state.regMsg}
            />
            <Button
              color="teal"
              fluid
              size="large"
              onClick={this.registerClick.bind(this)}
            >
              Register
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Registration;
