import React, { useEffect, useRef } from "react";
import commonmark from "commonmark";
import Prism from "prismjs";

/** 
 *  A React component for nicely rendering Markdown.
 *  Takes either a markdown string and a possibly empty array of image 
 *  information, or an already rendered htmlString and puts it in nicely 
 *  rendered form into React. This component adds syntax highlighting to any 
 *  code blocks and other prism.js features, if enabled.
 *
 *   The reason that this component can also take an htmlString is so that it 
 *   can work with the AuthorUpload component that needs to look at files a bit 
 *   more in depth (and hence already processes the markdown) as well as with the
 *   Question and Chat components that receive Markdown.
*/
function MarkdownRender(props) {
  let htmlString = props.htmlString;
  if (!htmlString) {
    htmlString = createHTMLString(props.md, props.images);
  }
  const articleRef = useRef(null);
  useEffect(() => {
    Prism.highlightAllUnder(articleRef.current);
  });
  return (
    <article
      className={"mdRender line-numbers " + props.className}
      ref={articleRef}
      dangerouslySetInnerHTML={{ __html: htmlString }}
    ></article>
  );
}

/**
 * Takes a Markdown string and a possibly empty array of images
 * and renders it into an HTML string with images via Data URLs.
 * @param {string} md 
 * @param {arrayh} images 
 */
function createHTMLString(md, images) {
  let reader = new commonmark.Parser();
  let writer = new commonmark.HtmlRenderer();
  let parsed = reader.parse(md);
  if (images.length > 0) {
    replaceImages(parsed, images);
  }
  return writer.render(parsed);
}

/**
 * Using the commonmark AST substitute real image data for the url in the 
 * Markdown document and produce a list of missing images if any. 
 * The imgInfo objects in the array have the form: {name: string, urlData: string}
 * @param {commonmarkAST} parsed 
 * @param {array} imgObjs 
 */
function replaceImages(parsed, imgObjs) {
  let imgMap = new Map();
  imgObjs.forEach(function (imgInfo) {
    imgMap.set(imgInfo.name, imgInfo.urlData);
  });
  let walker = parsed.walker();
  let event, node;
  let missing = [];
  while ((event = walker.next())) {
    node = event.node;
    if (node.type === "image" && event.entering) {
      if (imgMap.has(node.destination)) {
        node.destination = imgMap.get(node.destination);
      } else {
        missing.push(node.destination);
      }
    }
  }
  return missing;
}

export {createHTMLString, replaceImages}

export default MarkdownRender;
