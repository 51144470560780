import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Confirm,
  Divider,
  Form,
  Grid,
  Icon,
  Segment,
  Select,
  Table,
} from "semantic-ui-react";
import MarkdownRender from "./MarkdownRender";
import Statistics from "./Statistics";
import { clearQuestion } from "./ClearAndSaveUtil";
import offeredCourses from "../Server/currentCourses.json";

const alpha = "abcdefghij";
/**
 * Shows the responses to the current question and more...
 * Takes a question object, an array of users and responses as properties.
 */
class Responses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedResponses: "curr",
      showName: false,
      showId: false,
      showResponse: false,
      showStatistics: false,
      selIndResponse: null, // Selected individual response
      responseFile: null,
      openResponseConfirm: false,
      openClearHistConfirm: false,
    };
  }

  individualClick(index, event, data) {
    console.log(`You clicked: ${index}`);
    this.setState({ selIndResponse: index });
  }

  individualActive(index, q) {
    return (
      q &&
      index === this.state.selIndResponse &&
      q.content.meta.answerType === "singleBlock"
    );
  }

  /**
   * Format the responses for different types of questions/answers.
   *
   * @param {string} aType "singleChoice", "manyChoices", "singleBlock"
   * @param {*} response a number, array, or string
   */
  renderResponse(aType, response) {
    if (aType === "singleChoice") {
      return alpha[response];
    }
    if (aType === "manyChoices") {
      let answers = [];
      // TODO: Check to make sure we have an array of true/false in this case
      response.forEach(function (choice, i) {
        if (choice) {
          answers.push(alpha[i]);
        }
      });
      return answers.join(", ");
    }
    if (aType === "singleBlock") {
      // Only show a bit of a long response in the table
      if (response.length > 25) {
        return response.slice(0, 22) + " ...";
      }
      return response;
    }
  }

  setResponses(event, data) {
    this.setState({ selectedResponses: data.value });
  }

  showStuff(what, event, data) {
    console.log(`${what}, state: ${data.checked}`);
    switch (what) {
      case "id":
        this.setState({ showId: data.checked });
        break;
      case "name":
        this.setState({ showName: data.checked });
        break;
      case "response":
        this.setState({ showResponse: data.checked });
        break;
    }
  }

  toggleStats() {
    this.setState({ showStatistics: !this.state.showStatistics });
  }

  courseChange(event, data) {
    this.setState({ otherCourse: data.value });
  }

  render() {
    let that = this;
    let currentUsers = this.props.currentUsers;
    let currentResponses = this.props.responses;
    let currentQuestion = this.props.question;
    let responseHistory = this.props.responseHistory;
    if (this.state.selectedResponses !== "curr") {
      currentQuestion = responseHistory[this.state.selectedResponses].qMsg;
      currentResponses =
        responseHistory[this.state.selectedResponses].responses;
      // Only show messages from responding users.
      currentUsers = currentResponses.map(function (msg) {
        // Get chatId from user database if possible
        let user = that.props.registeredUsers.find(
          (u) => u.userId === msg.userId
        );
        let chatId = "Not Found";
        if (user) {
          chatId = user.chatId;
        }
        return { chatId: chatId, userId: msg.userId };
      });
    }
    const positive = <Icon color="green" name="check" size="large" />;
    const negative = <Icon color="red" name="ban" size="large" />;
    let indResponse = null;
    let rows = null;
    if (currentUsers) {
      rows = currentUsers.map(function (u, i) {
        // Find the answer from this user if any
        let content = currentResponses.find((r) => r.userId === u.userId);
        let responseElement = negative;
        if (content) {
          if (that.state.selIndResponse === i) {
            // used if we render MD answer
            indResponse = content.response;
          }
          if (that.state.showResponse) {
            responseElement = that.renderResponse(
              currentQuestion.content.meta.answerType,
              content.response
            );
          } else {
            responseElement = positive;
          }
        }

        let idEl = u.userId; // Show id
        if (!that.state.showId) {
          // Otherwise show an indicator that there is an id or not
          if (!idEl) {
            idEl = negative;
          } else {
            idEl = positive;
          }
        }
        return (
          <Table.Row
            key={"u" + i}
            onClick={that.individualClick.bind(that, i)}
            active={that.individualActive(i, currentQuestion)}
          >
            <Table.Cell>{i + 1}</Table.Cell>
            <Table.Cell>{idEl}</Table.Cell>
            <Table.Cell style={{ wordWrap: "anywhere" }}>
              {that.state.showName ? u.chatId : "XXX"}
            </Table.Cell>
            <Table.Cell>{responseElement}</Table.Cell>
          </Table.Row>
        );
      });
    }
    let selectOptions = [];
    selectOptions.push({ key: "curr", value: "curr", text: "Current" });
    responseHistory.forEach(function (rs, i) {
      if (rs.qMsg.content) {
        let qDate = new Date(rs.qMsg.datetime);
        let qTime = qDate.toLocaleTimeString();
        selectOptions.push({
          key: `r${i}`,
          value: i,
          text: rs.qMsg.content.meta.name + " " + qTime,
        });
      }
    });
    let rendIndResponse = null;
    let renderCurrentQuestion = null;
    let renderSummary = null;
    if (currentQuestion) {
      renderCurrentQuestion = (
        <Segment style={{ maxHeight: "50vh", overflow: "auto" }}>
          <MarkdownRender
            className="question"
            md={currentQuestion.content.md}
            images={currentQuestion.content.images}
          />
        </Segment>
      );
      if (this.state.showStatistics) {
        renderSummary = (
          <Segment>
            <Statistics
              question={currentQuestion}
              responses={currentResponses}
            />
          </Segment>
        );
      }
      if (
        currentQuestion.content.meta.answerType === "singleBlock" &&
        this.state.selIndResponse !== null &&
        indResponse
      ) {
        rendIndResponse = (
          <Segment style={{ maxHeight: "40vh", overflow: "auto" }}>
            <h2>Individual Response</h2>
            <MarkdownRender md={indResponse} images={[]} />
          </Segment>
        );
      }
    }
    // Other session options
    const courseOptions = [];
    offeredCourses.forEach(function (course) {
      courseOptions.push({ key: course, text: course, value: course });
    });
    return (
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            <h2>User Responses</h2>
            <Segment>
              <Form>
                <Form.Select
                  value={this.state.selectedResponses}
                  options={selectOptions}
                  onChange={this.setResponses.bind(this)}
                  label="Select Question"
                  inline
                />
              </Form>
            </Segment>
            <Segment style={{ maxHeight: "50vh", overflow: "auto" }}>
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    {/* Semantic UI based on 16 "column widths" */}
                    <Table.HeaderCell width={1}>#</Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {" "}
                      <Checkbox
                        label="User Id"
                        checked={this.state.showId}
                        onChange={this.showStuff.bind(this, "id")}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      <Checkbox
                        label="Name"
                        checked={this.state.showName}
                        onChange={this.showStuff.bind(this, "name")}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={8}>
                      <Checkbox
                        label="Response"
                        checked={this.state.showResponse}
                        onChange={this.showStuff.bind(this, "response")}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{rows}</Table.Body>
              </Table>
            </Segment>
            {rendIndResponse}
          </Grid.Column>
          <Grid.Column width={8}>
            <h2>The Question</h2>
            {renderCurrentQuestion}
            <div>
              <h2>
                Summary ({currentResponses.length} responses)
                <Icon name="caret down" onClick={this.toggleStats.bind(this)} />
                <Button
                  onClick={() =>
                    clearQuestion(this.props.ws, this.props.userInfo.chatId)
                  }
                >
                  Clear Question
                </Button>
              </h2>
              {renderSummary}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Responses;
