import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Menu,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import offeredCourses from "../Server/currentCourses.json";

class Participation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      course: offeredCourses[0],
      sessions: null
    };
  }


  fetchSessions() {
    let that = this;
    fetch("/courseSessions/" + this.state.course)
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          let info = `Status code: ${response.status}, ${response.statusText}`;
          console.log(response);
          return Promise.reject(info);
        }
      })
      .then(function (data) {
        console.log(data);
        // process session data into participation information
        that.setState({ sessions: data });
      })
      .catch(function (msg) {
        console.log("Something bad: " + msg);
      });
  }

  courseChange(event, data) {
    this.setState({ course: data.value });
  }

  render() {
    let users = this.props.registeredUsers.filter(u=>u.courses.includes(this.state.course));
    let partMap = null, sessInfo = null;
    if (this.state.sessions) {
      [partMap, sessInfo] = usersParticipation(users, this.state.sessions);
    }
    let courseSelector = [];
    offeredCourses.forEach(function (course) {
      courseSelector.push({ key: course, text: course, value: course });
    });
    const headingSelStyle = null;
    let rows = users.map(function (u, i) {
      let sessCells = null
      if (partMap && sessInfo) {
        let userArray = partMap.get(u.userId);
        sessCells = userArray.map((rCount,i)=><Table.Cell key={i}>{rCount}</Table.Cell>)
      }
      return (
        <Table.Row key={u.chatId} >
          <Table.Cell>{u.userId}</Table.Cell>
          <Table.Cell>{u.chatId}</Table.Cell>
          {sessCells}
        </Table.Row>
      );
    });
    let sessHeaders = null;
    if (sessInfo) {
      sessHeaders = sessInfo.map((sDate) => (
      <Table.HeaderCell key={sDate}>{sDate}</Table.HeaderCell>
    ));
    }
    return (
      <Segment style={{ minHeight: "80vh" }}>
        <Menu secondary>
          <Menu.Item>
            <Form.Select
              label="Course"
              options={courseSelector}
              value={this.state.course}
              onChange={this.courseChange.bind(this)}
              fluid
            />
          </Menu.Item>
          <Menu.Item>
            <Button onClick={this.fetchSessions.bind(this)}>
              Load Sessions
            </Button>
          </Menu.Item>
        </Menu>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={headingSelStyle}>UserId</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              {sessHeaders}
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
        </Table>
      </Segment>
    );
  }
}

/**
 * Produces a map of user participation in session.
 * Each users get an array where each entry is a count of the number of responses
 * from that user in that session.
 * 
 * @param {Array} users 
 * @param {Array} sessions 
 */
function usersParticipation(users, sessions) {
  let partMap = new Map();
  users.forEach(u=>partMap.set(u.userId, []));
  let filtSessions = sessions.filter(s => s.qAndAs.length > 0);
  let sessInfo = filtSessions.map(function(s){
    let sDate = new Date(s.start);
    return sDate.toLocaleString();
  })
  filtSessions.forEach(function(s, i){
    users.forEach(u=>partMap.get(u.userId).push(0));
    s.qAndAs.forEach(function(qAndA) {
      qAndA.responses.forEach(function(resp){
        if (partMap.has(resp.userId)) { // In case admin answers question
        let userArray = partMap.get(resp.userId);
        userArray[i] += 1;
        }
      })
    })
  });
  return [partMap, sessInfo];
}

export default Participation;
