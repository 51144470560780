import React, { Component } from "react";
import { Checkbox, Form, Grid, Input, Segment } from "semantic-ui-react";
import MarkdownRender from "./MarkdownRender";
import {MChoiceSAnswer, MChoiceMAnswer} from "./answers";

// Sample question messages for development purposes
import q1 from "../AuthorSamples/mChoiceSanswer1.json";
import q2 from "../AuthorSamples/mChoiceMans.json";
import q3 from "../AuthorSamples/sampleQMsg1.json";
import CodeAnswer from "./CodeAnswer";

/**
 *  A React component to show the received question and to aid in formulating
 *  the reponse message.
 * 
 *  **Example Use**:
 *  <Question
      ws={this.ws}
      name={this.state.name}
      q={this.state.question}
      done={this.state.responseSent}
      response={this.state.response}
      sentResponse={this.setResponse.bind(this)}
    />
 *
 */
class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {response: this.props.response, responseSent: false};
  }

  updateResponse(res) {
    this.setState({response: res});
  }

  sendResponse() {
    console.log("Preparing Response");
    let q = this.props.q;
    // Prepare the contents, we use the question metadata in the response
    let content = { response: this.state.response, meta: q.content.meta};
    let dt = new Date();
    let message = {
      version: 1,
      type: "response",
      datetime: dt.toISOString(),
      to: "admin",
      from: this.props.userInfo.userId,
      content: content,
    };
    console.log(message);
    this.props.ws.send(JSON.stringify(message));
    this.props.sentResponse(this.state.response);
  }
  
  render() {
    let mdRender = null;
    let responseComp = null;
    let q = this.props.q;
    // let q = q3;
    if (q) {
      let questionType = q.content.meta.questionType;
      let answerType = q.content.meta.answerType;
      if (questionType === "multipleChoice") {
        // Style the choices with a, b, c, ...
        let numChoices = q.content.choices.length;
        if (answerType === "singleChoice") {
          responseComp = <MChoiceSAnswer numChoices={numChoices} 
           update={this.updateResponse.bind(this)} 
           response={this.state.response} />;
        } else {
          responseComp = <MChoiceMAnswer numChoices={numChoices} 
          update={this.updateResponse.bind(this)} 
          response={this.state.response}/>;
        }
        
      } else {
        responseComp = <CodeAnswer update={this.updateResponse.bind(this)} 
        response={this.state.response} />;
      }

      mdRender = <MarkdownRender className="question" md={q.content.md} images={q.content.images} />;
    }
    /* divided="vertically" */
    return (
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            <h2>Question</h2>
            <Segment>{mdRender}</Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <h2>Response <button onClick={this.sendResponse.bind(this)} disabled={this.props.done}>Send</button></h2>
            <Segment>{responseComp}</Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


export default Question;
