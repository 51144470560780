import React, { Component } from "react";
import { Button, Dropdown, Input, Menu } from "semantic-ui-react";
import commonmark from "commonmark";
import matter from "gray-matter";
import { FileSummary } from "./FileSummary";
import { replaceImages } from "./MarkdownRender";
import {clearQuestion} from "./ClearAndSaveUtil";

/**
 * A React component for previewing (uploading) of Markdown question/survey files and
 * for sending questions/survey messages to students based on those files.
 *
 * Since markdown files can contain images this component will try to associate
 * image files with their markdown files. However, it cannot look into other
 * directories hence associated image files must be in the same directory and
 * be referenced by relative URLs (the simplist way to do this).
 */
class AuthorUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Takes the list of files furnished in the event and processes them.
   * Sort files into images and markdown files, reads them appropriately and
   * updates the app state with this information.
   * @param {object} event
   */
  async filesChange(event) {
    //
    let files = event.target.files;
    console.log(files);
    let mdFiles = [];
    let imgFiles = [];
    for (let i = 0; i < files.length; i++) {
      let f = files.item(i);
      if (f.name.endsWith(".md")) {
        mdFiles.push({ file: f, parsed: null, images: [] });
      }
      if (f.type.includes("image")) {
        imgFiles.push({ file: f, name: f.name, urlData: null });
      }
    }
    // Process all the images
    for (let imgStuff of imgFiles) {
      imgStuff.urlData = await preadFileAsDataURL(imgStuff.file);
    }
    // Process all the markdown files
    let reader = new commonmark.Parser();
    let writer = new commonmark.HtmlRenderer();
    for (let mdStuff of mdFiles) {
      // Separate front matter (YAML) from the following markdown
      let text = await preadFileAsText(mdStuff.file);
      let frontContent = matter(text);
      mdStuff.meta = frontContent.data; // Can use this info more later
      mdStuff.meta.name = mdStuff.file.name;
      mdStuff.rawMD = frontContent.content;
      mdStuff.parsed = reader.parse(mdStuff.rawMD);
      mdStuff.images = getImageList(mdStuff.parsed);
      mdStuff.missing = replaceImages(mdStuff.parsed, imgFiles);
      mdStuff.htmlString = writer.render(mdStuff.parsed); // result is a String
    }
    this.props.setSelectedMD(null);
    this.props.setFiles(mdFiles, imgFiles);
  }

  /**
   * Extracts the choices if a multiple choice question. Only the last <ol> is 
   * considered.
   * @param {Object} q The question object.
   */
  extractChoices(q) {
    if (q.meta.questionType !== "multipleChoice") {
      return null;
    }
    let tempDiv = document.createElement("div");
    tempDiv.innerHTML = q.htmlString;
    let ols = tempDiv.querySelectorAll("ol");
    if (ols.length == 0) {
      console.log("No outline list in multiple choice question");
      return null;
    }
    let listItems = ols[ols.length-1].querySelectorAll("li");
    if (listItems.length == 0) {
      return null;
    }
    let choices = [];
    listItems.forEach(function (li) {
      choices.push(li.innerText.trim());
    });
    return choices;
  }

  /**
   * Prepares and sends question/survey WebSocket message based on the currently
   * selected question.
   */
  sendQuestion() {
    console.log("Preparing Question to Send");
    let q = this.props.mdFiles[this.props.selectedMD];
    // Prepare the contents
    let content = { md: q.rawMD, meta: q.meta };
    // If multiple choice extract choices here
    let choices = this.extractChoices(q);
    if (choices) {
      content.choices = choices;
    }
    let that = this;
    let images = q.images.map(function (name) {
      let imgFile = that.props.imgFiles.find((img) => img.file.name === name);
      if (imgFile) {
        return { name: name, urlData: imgFile.urlData };
      }
    });
    content.images = images;

    let dt = new Date();
    let message = {
      version: 1,
      type: "question",
      datetime: dt.toISOString(),
      to: "all",
      from: this.props.userInfo.userId,
      content: content,
    };
    console.log(message);
    this.props.ws.send(JSON.stringify(message));
    this.props.updateSentQs({
      name: q.file.name,
      description: q.meta.description,
    });
  }

  render() {
    let sentItems = this.props.sentQs.map(function (q, i) {
      return (
        <Dropdown.Item
          text={q.name}
          title={q.description}
          key={"si" + i}
        ></Dropdown.Item>
      );
    });
    return (
      <div id="Upload">
        <Menu>
          <Menu.Item header>Author Upload</Menu.Item>
          <Menu.Item>
            <Input
              type="file"
              multiple
              onChange={this.filesChange.bind(this)}
              accept=".md, .png, .jpg, .jpeg"
            />
          </Menu.Item>
          <Menu.Item position="right">
            <Button onClick={this.sendQuestion.bind(this)} disabled={this.props.selectedMD === null}>
              Send Question
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button onClick={()=>clearQuestion(this.props.ws, this.props.userInfo.chatId)}>
              Clear Question
            </Button>
          </Menu.Item>
          <Dropdown text="Previously Sent Questions" item position="right">
            <Dropdown.Menu>{sentItems}</Dropdown.Menu>
          </Dropdown>
        </Menu>

        <FileSummary
          mdFiles={this.props.mdFiles}
          imgFiles={this.props.imgFiles}
          ws={this.props.ws}
          name={this.props.name}
          setSelectedMD={this.props.setSelectedMD}
          selectedMD={this.props.selectedMD}
          sentQs={this.props.sentQs}
          updateSentQs={this.props.updateSentQs}
        />
      </div>
    );
  }
}

/**
 * A function to read text with the FileReader API via promises as mentioned in:
  https://blog.shovonhasan.com/using-promises-with-filereader/

 * @param {File} inputFile 
 */
function preadFileAsText(inputFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsText(inputFile);
  });
}

/**
 * A function to read an image file as a Data URL with the FileReader API via
 * promises.
 *
 * @param {File} inputFile
 */
function preadFileAsDataURL(inputFile) {
  const temporaryFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
}

/**
 * Get a list of images referenced in a Markdown file using the commonmark
 * abstract syntax tree (AST).
 * @param {commonmarkAST} parsed
 */
function getImageList(parsed) {
  let walker = parsed.walker();
  let event, node;
  let sources = [];
  while ((event = walker.next())) {
    node = event.node;
    if (node.type === "image" && event.entering) {
      sources.push(node.destination);
    }
  }
  return sources;
}

export {preadFileAsText};
export default AuthorUpload;
