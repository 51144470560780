import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Menu,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import offeredCourses from "../Server/currentCourses.json";

class ManageSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      courseFilter: "all",
    };
  }

  componentDidMount() {
    this.fetchSessions();
  }

  fetchSessions() {
    let that = this;
    fetch("/courseSessions")
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          let info = `Status code: ${response.status}, ${response.statusText}`;
          console.log(response);
          return Promise.reject(info);
        }
      })
      .then(function (data) {
        // console.log(data);
        data.forEach(function (session) {
          session.action = "none";
        });
        that.setState({ sessions: data });
      })
      .catch(function (msg) {
        console.log("Something bad: " + msg);
      });
  }

  courseFiltChange(event, data) {
    this.setState({ courseFilter: data.value });
  }


  actionChange(sessionId, event, data) {
    let sessionUpdate = this.state.sessions.map(function (session) {
      if (session._id === sessionId) {
        return Object.assign({}, session, { action: data.value });
      } else {
        return session;
      }
    });
    this.setState({ sessions: sessionUpdate });
  }

  async applyActions() {
    console.log("About to apply session actions!");
    let that = this;
    let updatedSessions = this.state.sessions.map(function (session) {
      switch (session.action) {
        // Only delete for now but maybe other actions later...
        case "delete":
          return that.deleteSession(session);
        default:
          return session;
      }
    });
    updatedSessions = await Promise.all(updatedSessions);
    console.log(updatedSessions);
    updatedSessions = updatedSessions.filter((u) => u !== undefined);
    this.setState({ sessions: updatedSessions });
  }

  async deleteSession(session) {
    return fetch(`/courseSessions/${session._id}`, {
      method: "DELETE",
    }).then(function (response) {
      console.log(
        "Request status code: ",
        response.statusText,
        response.status,
        response.type
      );
      if (response.status === 200) {
        return undefined; // user has be delete so will return undefined
      } else {
        return session;
      }
    });
  }

  render() {
    let that = this;
    let filteredSessions = this.state.sessions.filter(function (s) {
      if (that.state.courseFilter === "all") {
        return true;
      }
      return s.course === that.state.courseFilter;
    });

    const actionOptions = [
      { key: "none", text: "None", value: "none" },
      { key: "delete", text: "Delete", value: "delete" },
    ];

    const courseFilter = [{ key: "all", text: "All", value: "all" }];
    offeredCourses.forEach(function (course) {
      courseFilter.push({ key: course, text: course, value: course });
    });
    const headingSelStyle = null;
    let rows = filteredSessions.map(function (s, i) {
      let negative = s.action === "delete";
      let startDate = new Date(s.start);
      return (
        <Table.Row key={s._id} negative={negative}>
          <Table.Cell>{s.course}</Table.Cell>
          <Table.Cell>{startDate.toLocaleString()}</Table.Cell>
          <Table.Cell>{s.qCount}</Table.Cell>
          <Table.Cell>
            <Form.Select
              options={actionOptions}
              value={s.action}
              onChange={that.actionChange.bind(that, s._id)}
              fluid
            />
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Segment style={{ minHeight: "80vh" }}>
        <Menu secondary>
          <Menu.Item>
            <p>Showing {filteredSessions.length} class sessions</p>
          </Menu.Item>
          <Menu.Item >
            <Button onClick={this.applyActions.bind(this)}>Apply Actions</Button>
          </Menu.Item>
        </Menu>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={headingSelStyle}>
                <Form.Select
                  label="Course"
                  options={courseFilter}
                  value={this.state.courseFilter}
                  onChange={this.courseFiltChange.bind(this)}
                  fluid
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Start</Table.HeaderCell>
              <Table.HeaderCell># Questions</Table.HeaderCell>

              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default ManageSessions;
