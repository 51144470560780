import React, { Component } from "react";
import config from "../configAddrPorts.json";
import Chat from "./Chat";
import Login from "./Login";
import Registration from "./Registration";
import AdminCourse from "./AdminCourse";
import AuthorUpload from "./AuthorUpload";
import Question from "./Question";
import Responses from "./Responses";
import ManageUsers from "./ManageUsers";
import ClassSessions from "./ClassSessions";
import { Container, Icon, Menu, Segment, Sidebar } from "semantic-ui-react";

const CHAT_MSG_LIMIT = 500;
const WIDTH_LIMIT = 350;

/**
 * The main App React component for the client side of our computer science
 * oriented audience response system (CSOARS).
 */
class App extends Component {
  constructor(props) {
    super(props);
    console.log(config);
    this.state = {
      // General UI
      windowWidth: window.innerWidth,
      sidebarVisible: false,
      // General state
      userInfo: { chatId: "", userId: "", role: "guest" },
      show: "login",
      course: "",
      sockStatus: "Closed",

      // Chat related
      chatMessages: [],

      // System status
      currentUsers: [],
      chatEnabled: true,
      registrationEnabled: false,

      // Student related
      question: null, // Latest question
      response: null, // Students response
      responseSent: false,
      userChatEnabled: false,

      // Teacher related
      mdFiles: [],
      imgFiles: [],
      selectedMD: null,
      sentQs: [],
      currentResponses: [], // Contains responses for current question
      responseHistory: [], // Contains responses for previous questions
      registeredUsers: [],
    };
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
    // console.log(`window width: ${this.state.windowWidth}`);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  componentWillUnMount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  setSidebar(visible) {
    this.setState({ sidebarVisible: visible });
  }

  clearChat() {
    this.setState({ chatMessages: [] });
  }

  setResponse(answer) {
    this.setState({ response: answer, responseSent: true });
  }

  setResponseHistory(hist) {
    // clear the question too
    this.setState({
      currentResponses: [],
      responseHistory: hist,
      question: null,
    });
  }

  //adding the recently sent message to recMessage[] in order to remember
  setSentChat(sentMessage) {
    let tempMsgs = this.state.chatMessages.concat(sentMessage);
    while (tempMsgs.length > CHAT_MSG_LIMIT) {
      tempMsgs.shift();
    }
    this.setState({
      chatMessages: tempMsgs,
    });
  }

  setFiles(mdFiles, imgFiles) {
    this.setState({ mdFiles: mdFiles, imgFiles: imgFiles });
  }

  setSelectedMD(i) {
    this.setState({ selectedMD: i });
  }

  setUpSocket() {
    console.log(config);
    // Need wss for classroom.grotto-networking.com and ws for local stuff...
    let wsURL = config.clientWsURL;
    console.log(`wsURL: ${wsURL}`);
    this.ws = new WebSocket(wsURL);
    this.setState({ sockStatus: "Unknown" });
    this.ws.addEventListener("open", this.onWebSockOpen.bind(this));
    this.ws.addEventListener("message", this.onWebSockMessage.bind(this));
    this.ws.addEventListener("close", this.onWebSockClose.bind(this));
    this.ws.addEventListener("error", this.onWebSockError.bind(this));
  }

  logout() {
    // this.ws.close(); // Here or server? Closing on server...
    this.setState({
      userInfo: { chatId: "", userId: "", role: "guest" },
      show: "login",
      chatMessages: [], // clear messages
      currentUsers: [],
      question: null, // clear question
      response: null, // clear response
      responseSent: false,
      selectedMD: null,
      sentQs: [],
    });
    fetch("/logout")
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        return response.json();
      })
      .then(function (message) {
        console.log(message);
      });
  }

  setUser(userInfo) {
    if (userInfo.role !== "guest") {
      this.setUpSocket();
    }
    if (userInfo.role === "student") {
      this.setState({
        userInfo: userInfo,
        show: "chat",
        question: userInfo.curQuestion,
      });
    }
    if (userInfo.role === "admin") {
      this.setState({
        userInfo: userInfo,
        show: "chat",
        chatMessages: [],
        responseHistory: [],
        question: userInfo.curQuestion,
        currentResponses: userInfo.curResponses,
      });
      // Go get all registered users here...
      this.fetchRegisteredUsers();
      this.fetchResponseHistory();
    }
  }

  fetchRegisteredUsers() {
    let that = this;
    fetch("/users")
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          let info = `Status code: ${response.status}, ${response.statusText}`;
          console.log(response);
          return Promise.reject(info);
        }
      })
      .then(function (data) {
        // console.log(data);
        that.setState({ registeredUsers: data });
      })
      .catch(function (msg) {
        console.log("Something bad: " + msg);
      });
  }

  fetchResponseHistory() {
    let that = this;
    fetch("/currentSession")
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          let info = `Status code: ${response.status}, ${response.statusText}`;
          console.log(response);
          return Promise.reject(info);
        }
      })
      .then(function (data) {
        // console.log(data);
        if (data.qAndAs) {
          that.setState({ responseHistory: data.qAndAs.reverse() });
        }
      })
      .catch(function (msg) {
        console.log("Something bad: " + msg);
      });
  }

  setShow(thing) {
    this.setState({ show: thing });
  }

  onWebSockError(event) {
    console.log("Websocket Error!");
    console.log(event);
    // Reset to begining
    this.setState({
      userInfo: { chatId: "", userId: "", role: "guest" },
      show: "chat",
      course: "",
      sockStatus: "Closed",
    });
  }
  onWebSockOpen() {
    console.log("Websocket opened!");
    let dt = new Date();
    let message = {
      version: 1,
      type: "chat",
      datetime: dt.toISOString(),
      to: "admin",
      from: this.state.userInfo.chatId,
      content: `User ${this.state.userInfo.chatId} just joined!`,
    };
    this.setState({ sockStatus: "Open" });
    this.ws.send(JSON.stringify(message));
  }

  onWebSockClose() {
    // Check to see if user logged out, if not reconnect socket
    if (this.state.userInfo.role !== "guest") {
      console.log("Socket got closed but I didn't log out");
      this.setUpSocket();
    }
    this.setState({ sockStatus: "Closed" });
  }

  onWebSockMessage(event) {
    // console.log("React WebSocket message received:", event);
    let parsedData = JSON.parse(event.data);
    switch (parsedData.type) {
      case "chat":
        let tempMsgs = this.state.chatMessages.concat(parsedData);
        while (tempMsgs.length > CHAT_MSG_LIMIT) {
          tempMsgs.shift();
        }
        this.setState({
          chatMessages: tempMsgs,
        });
        break;
      case "status":
        let users = parsedData.content.users;
        let courseInfo = parsedData.content.course;
        console.log("received status message");
        console.log(users);
        console.log(courseInfo);
        this.setState({
          currentUsers: users,
          course: courseInfo.course,
          chatEnabled: courseInfo.chatEnabled,
          userChatEnabled: courseInfo.userChatEnabled,
          registrationEnabled: courseInfo.registrationEnabled
        });
        break;
      case "question":
        // Take them directly to question component if a student
        // clean up current responses, add to response history.
        if (this.state.question && this.state.userInfo.role === "admin") {
          this.fetchResponseHistory();
        }
        let show = this.state.show;
        // Check for "empty" question
        let newQuestion = null;
        if (parsedData.content === null) {
          // Empty question clear things out
          if (this.state.userInfo.role === "student") {
            show = "chat";
          }
        } else {
          if (this.state.userInfo.role === "student") {
            show = "question";
          }
          newQuestion = parsedData;
        }
        this.setState({
          question: newQuestion,
          responseSent: false,
          currentResponses: [],
          response: null,
          show: show,
        });
        break;
      case "response":
        // students should not receive responses but check just in case
        if (this.state.userInfo.role !== "admin") {
          console.log("Error: A student received a response message!!!");
          return;
        }
        this.processResponse(parsedData);
        break;

      default:
        console.log("Error: Unknown type of message");
    }
  }

  /**
   * For teacher/admin use processes received student responses
   * @param {Object} response A response message object
   */
  processResponse(responseMessage) {
    let rcontent = responseMessage.content;
    // Check that the response corresponds to the current question
    if (this.state.question.content.meta.name !== rcontent.meta.name) {
      console.log("Error: Received a response that doesn't match the question");
      return;
    }
    let userResponse = {
      datetime: responseMessage.datetime,
      chatId: responseMessage.from,
      userId: responseMessage.fromId,
      response: rcontent.response,
    };
    // Check if user already responded, we shouldn't allow this to happen
    if (
      this.state.currentResponses.some(function (r) {
        return r.chatId === userResponse.chatId;
      })
    ) {
      console.log(
        `Error: Received a response twice from user: ${userResponse.chatId}`
      );
      return;
    }
    this.setState({
      currentResponses: this.state.currentResponses.concat(userResponse),
    });
  }

  createPageHeader() {
    let headerInfo = "CSOARS";
    if (this.state.course) {
      headerInfo = this.state.course;
    }
    let menuIcon = (
      <Menu.Item as="a" onClick={this.setSidebar.bind(this, true)}>
        <Icon name="bars" />
        Menu
      </Menu.Item>
    );
    if (this.state.sidebarVisible) {
      menuIcon = null;
    }
    let menuItems = null;
    if (this.state.windowWidth > WIDTH_LIMIT) {
      menuIcon = null;
      menuItems = this.createMenuItems();
    }
    return (
      <Menu fixed="top" inverted>
        <Menu.Item
          header
          title="A computer science oriented audience response system"
        >
          {headerInfo}
        </Menu.Item>
        {menuIcon}
        {menuItems}
      </Menu>
    );
  }

  createMenuItems() {
    let menuItems = [];
    if (this.state.userInfo.role === "guest") {
      menuItems = [
        <Menu.Item
          key="login"
          name="Login"
          active={this.state.show === "login"}
          onClick={this.setShow.bind(this, "login")}
        />,
        <Menu.Item
          key="register"
          name="Register"
          active={this.state.show === "register"}
          onClick={this.setShow.bind(this, "register")}
        />,
      ];
    }
    if (this.state.userInfo.role !== "guest") {
      menuItems = [
        <Menu.Item
          key="MSG"
          name="chat"
          active={this.state.show === "chat"}
          onClick={this.setShow.bind(this, "chat")}
        />,
      ];
      if (this.state.userInfo.role === "admin") {
        menuItems.push(
          <Menu.Item
            key="AdminCourse"
            name="adminCourse"
            active={this.state.show === "adminCourse"}
            onClick={this.setShow.bind(this, "adminCourse")}
          />
        );
        menuItems.push(
          <Menu.Item
            key="authorUpload"
            name="Send Question"
            active={this.state.show === "authorUpload"}
            onClick={this.setShow.bind(this, "authorUpload")}
          />
        );
        menuItems.push(
          <Menu.Item
            key="responses"
            name="responses"
            active={this.state.show === "responses"}
            onClick={this.setShow.bind(this, "responses")}
          />
        );
                menuItems.push(
                  <Menu.Item
                    key="classSessions"
                    name="Sessions"
                    active={this.state.show === "classSessions"}
                    onClick={this.setShow.bind(this, "classSessions")}
                  />
                );
        menuItems.push(
          <Menu.Item
            key="manageUsers"
            name="manageUsers"
            active={this.state.show === "manageUsers"}
            onClick={this.setShow.bind(this, "manageUsers")}
          />
        );
      }
      if (this.state.question) {
        menuItems.push(
          <Menu.Item
            key="Question"
            name="question"
            active={this.state.show === "question"}
            onClick={this.setShow.bind(this, "question")}
          />
        );
      }
      menuItems.push(
        <Menu.Item
          key="Logout"
          name="logout"
          onClick={this.logout.bind(this)}
        />
      );
    }
    return menuItems;
  }

  updateSentQs(qInfo) {
    this.setState({ sentQs: this.state.sentQs.concat(qInfo) });
  }

  clearCourse() { // After closing the course
    this.setState({sentQs: [],
      currentResponses: [],
      responseHistory: []});
  }

  render() {
    let contents = "";

    switch (this.state.show) {
      case "login":
        contents = (
          <Login
            setUser={this.setUser.bind(this)}
            setRegister={this.setShow.bind(this, "register")}
          />
        );
        break;
      case "register":
        contents = <Registration />; // For development
        break;
      case "chat":
        contents = (
          <Chat
            course={this.state.course}
            chatMessages={this.state.chatMessages}
            ws={this.ws}
            userInfo={this.state.userInfo}
            currentUsers={this.state.currentUsers}
            setSent={this.setSentChat.bind(this)}
            enabled={this.state.chatEnabled && this.state.userChatEnabled}
            clearChat={this.clearChat.bind(this)}
          />
        );
        break;
      case "adminCourse":
        contents = (
          <AdminCourse
            course={this.state.course}
            currentUsers={this.state.currentUsers}
            sockStatus={this.state.sockStatus}
            courseChat={this.state.chatEnabled}
            registrationEnabled={this.state.registrationEnabled}
            ws={this.ws}
            userInfo={this.state.userInfo}
            registeredUsers={this.state.registeredUsers}
            clearCourse={this.clearCourse.bind(this)}
          />
        );
        break;
      case "authorUpload":
        contents = (
          <AuthorUpload
            ws={this.ws}
            userInfo={this.state.userInfo}
            setFiles={this.setFiles.bind(this)}
            setSelectedMD={this.setSelectedMD.bind(this)}
            selectedMD={this.state.selectedMD}
            mdFiles={this.state.mdFiles}
            imgFiles={this.state.imgFiles}
            sentQs={this.state.sentQs}
            updateSentQs={this.updateSentQs.bind(this)}
          />
        );
        break;
      case "question":
        contents = (
          <Question
            ws={this.ws}
            userInfo={this.state.userInfo}
            q={this.state.question}
            done={this.state.responseSent}
            response={this.state.response}
            sentResponse={this.setResponse.bind(this)}
          />
        );
        break;
      case "responses":
        contents = (
          <Responses
            course={this.state.course}
            responses={this.state.currentResponses}
            question={this.state.question}
            currentUsers={this.state.currentUsers}
            responseHistory={this.state.responseHistory}
            setResponseHistory={this.setResponseHistory.bind(this)}
            ws={this.ws}
            userInfo={this.state.userInfo}
            registeredUsers={this.state.registeredUsers}
          />
        );
        break;
      case "manageUsers":
        contents = <ManageUsers />;
        break;
      case "classSessions":
        contents = (
          <ClassSessions registeredUsers={this.state.registeredUsers} />
        );
        break;
      default:
        contents = <h2> Something weird happened! </h2>;
    }

    return (
      <>
        {this.createPageHeader()}
        <div className="GregDiv" style={{ width: "100vw", marginTop: "39px" }}>
          <Sidebar.Pushable as={Segment}>
            <Sidebar
              as={Menu}
              animation="overlay"
              onHide={this.setSidebar.bind(this, false)}
              direction="left"
              inverted
              vertical
              visible={this.state.sidebarVisible}
              width="thin"
            >
              {this.createMenuItems()}
            </Sidebar>
            <Sidebar.Pusher>
              <Container>{contents}</Container>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </>
    );
  }
}

export default App;
