import React, { Component } from "react";
import { Checkbox, Form } from "semantic-ui-react";

class MChoiceSAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = { answer: this.props.response };
  }

  handleChange(event, data) {
    this.setState({ answer: data.value });
    this.props.update(data.value);
  }
  render() {
    const letters = "abcdefghij";
    let choices = [];
    for (let i = 0; i < this.props.numChoices; i++) {
      choices.push(
        <Form.Field key={"choice" + i}>
          <Checkbox
            radio
            label={`(${letters[i]})`}
            name="answerGroup"
            value={i}
            checked={this.state.answer === i}
            onChange={this.handleChange.bind(this)}
          />
        </Form.Field>
      );
    }
    return (
      <Form>
        <Form.Group widths="equal">{choices}</Form.Group>
      </Form>
    );
  }
}

class MChoiceMAnswer extends Component {
  constructor(props) {
    super(props);
    let checked = [];
    for (let i = 0; i < this.props.numChoices; i++) {
      checked.push(false);
    }
    if (this.props.response) {
      checked = this.props.response.slice();
    }
    this.state = { checked: checked };
    this.props.update(checked);
  }

  handleChange(event, data) {
    console.log(data);
    let checked = this.state.checked.map(function(c, i){
        if (data.value === i) {
            return !c
        } else {
            return c;
        }
    });

    this.setState({ checked: checked });
    this.props.update(checked);
  }
  render() {
    const letters = "abcdefghij";
    let choices = [];
    for (let i = 0; i < this.props.numChoices; i++) {
      choices.push(
        <Form.Field key={"choice" + i}>
          <Checkbox
            label={`(${letters[i]})`}
            name="answerGroup"
            value={i}
            checked={this.state.checked[i]}
            onChange={this.handleChange.bind(this)}
          />
        </Form.Field>
      );
    }
    return (
      <Form>
        <Form.Group widths="equal">{choices}</Form.Group>
      </Form>
    );
  }
}

export { MChoiceSAnswer, MChoiceMAnswer };
