import React, { Component } from "react";
import { Grid, Icon, Image, List, Segment, Table } from "semantic-ui-react";
import MarkdownRender from "./MarkdownRender";

/**
 * A React component to show a summary of all "uploaded" files and shows 
 * rendered HTML for the currently selected markdown file if any.
 * 
 * Note that by "uploaded" here we mean files uploaded into the **browser** from the
 * local filesystem and not to a server.
 */
class FileSummary extends Component {
  constructor(props) {
    super(props);
    this.articleRef = React.createRef();
  }

  showMarkdown(i, event) {
    this.props.setSelectedMD(i);
  }

  render() {
    let mdFiles = this.props.mdFiles;
    let imgFiles = this.props.imgFiles;
    let that = this;
    let mdItems = mdFiles.map(function (mdInfo, i) {
      let sent = null;
      if (that.props.sentQs.some((sentQ) => sentQ.name === mdInfo.file.name)) {
        sent = <Icon color="green" name="checkmark" size="large" />;
      }
      return (
        <Table.Row
          key={"md" + i}
          onClick={that.showMarkdown.bind(that, i)}
          active={that.props.selectedMD === i}
        >
          <Table.Cell style={{ wordWrap: "anywhere" }}>
            {mdInfo.file.name}
          </Table.Cell>
          <Table.Cell>{mdInfo.meta.description}</Table.Cell>
          <Table.Cell
            style={{ wordWrap: "anywhere" }}
            error={mdInfo.missing.length > 0}
          >
            {mdInfo.images.join(", ")}
          </Table.Cell>
          <Table.Cell>{sent}</Table.Cell>
        </Table.Row>
      );
    });
    let imgItems = imgFiles.map(function (imgInfo, i) {
      return (
        <List.Item key={"img" + i}>
          <Image size="small" src={imgInfo.urlData} />
          <List.Content>
            <List.Header>{imgInfo.file.name}</List.Header>
            <List.Description>
              size: {imgInfo.file.size}, type: {imgInfo.file.type}
            </List.Description>
          </List.Content>
        </List.Item>
      );
    });
    let mdRender = null;
    let mdTitle = null;
    if (this.props.selectedMD != null) {
      let currMD = this.props.mdFiles[this.props.selectedMD];
      mdRender = <MarkdownRender htmlString={currMD.htmlString} />;
      mdTitle = (
        <h2 style={{ fontStyle: "italic" }}>
          Rendering of file: {currMD.file.name}
        </h2>
      );
    }
    return (
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            <Table celled selectable style={{height: "50vh", display:"inline-block", overflow: "auto"}}>
              <Table.Header>
                <Table.Row>
                  {/* Semantic UI based on 16 "column widths" */}
                  <Table.HeaderCell width={4}>File name</Table.HeaderCell>
                  <Table.HeaderCell width={6}>Description</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Images</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Sent</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{mdItems}</Table.Body>
            </Table>
            <details>
              <summary>Image Files</summary>
              <List style={{height: "40vh", overflow: "auto"}}>{imgItems}</List>
            </details>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment>
            {mdTitle}
            {mdRender}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export { FileSummary };
