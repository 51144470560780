import React, { Component } from "react";
import MarkdownRender from "./MarkdownRender";
import { Checkbox, Form, Segment, TextArea } from "semantic-ui-react";

class CodeAnswer extends Component {
  constructor(props) {
    super(props);
    let start = "";
    if(this.props.response) {
      start = this.props.response;
    }
    this.state = { answer: start };
  }

  textChange(event, data) {
      this.setState({answer: data.value});
      this.props.update(data.value);
  }
  render() {
    return (
      <div>
        <h2>Answer (text or Markdown)</h2>
        <Form>
        <TextArea onChange={this.textChange.bind(this)} value={this.state.answer}/>
        </Form>
        <Segment>
            <h3>Rendered Markdown</h3>
        <MarkdownRender md={this.state.answer} images={[]} />
        </Segment>
        
      </div>
    );
  }
}

export default CodeAnswer;
