import React from "react";
import {
  Tab,
} from "semantic-ui-react";
import ManageSessions from "./ManageSessions";
import Participation from "./Participation";

/**
  Displays information about class sessions.
 */
class ClassSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const panes = [
      {
        menuItem: "Participation",
        render: () => (
          <Tab.Pane>
            <Participation registeredUsers={this.props.registeredUsers} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Manage Sessions",
        render: () => (
          <Tab.Pane>
            <ManageSessions />
          </Tab.Pane>
        ),
      },
    ];

    return <Tab panes={panes} />;
  }
}

export default ClassSessions;
