import React, { Component } from "react";
import {
  Button,
  Confirm,
  Form,
  Grid,
  Message,
  Segment,
  Select,
  Table,
  TextArea,
} from "semantic-ui-react";
import MarkdownRender from "./MarkdownRender";
import {chatHistDownload} from "./ClearAndSaveUtil";

/**
 * Chat functionality with Markdown.
 * 
 * Usage:
 * <Chat
    chatMessages={this.state.chatMessages}
    ws={this.ws}
    userInfo={this.state.userInfo}
    currentUsers={this.state.currentUsers}
    setSent={this.setSentChat.bind(this)}
    enabled={...}
    clearChat={...}
  />
 */
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessage: "",
      to: "all",
      selected: null,
      column: "datetime",
      direction: "descending",
      clearConfirmOpen: false,
    };
  }

  onInput(event) {
    this.setState({ sendMessage: event.target.value });
  }

  send() {
    let dt = new Date();
    let message = {
      version: 1,
      type: "chat",
      datetime: dt.toISOString(),
      to: this.state.to,
      from: this.props.userInfo.chatId,
      content: this.state.sendMessage,
    };
    this.props.ws.send(JSON.stringify(message));
    this.setState({ sendMessage: "" });
    this.props.setSent(message);
  }

  toChange(event, data) {
    this.setState({ to: data.value });
  }

  setSelected(index) {
    this.setState({ selected: index });
    console.log(`you selected the ${index + 1} row`);
  }

  createMessageRow(m, i) {
    let locDt = new Date(m.datetime);
    let lines = m.content.split("\n");
    let contentDisplay;
    if (lines.length > 1) {
      contentDisplay = (
        <span>
          {lines[0]} <em> and more...</em>
        </span>
      );
    } else {
      contentDisplay = lines[0];
    }
    return (
      <Table.Row
        key={i}
        onClick={this.setSelected.bind(this, i)}
        active={this.state.selected === i}
      >
        <Table.Cell>{locDt.toLocaleTimeString()}</Table.Cell>
        <Table.Cell>{m.from}</Table.Cell>
        <Table.Cell>{m.to}</Table.Cell>
        <Table.Cell>{contentDisplay}</Table.Cell>
      </Table.Row>
    );
  }

  handleSort(col) {
    if (this.state.column === col) {
      if (this.state.direction === "ascending") {
        this.setState({ direction: "descending" });
        return;
      } else {
        this.setState({ direction: "ascending" });
        return;
      }
    } else {
      this.setState({ column: col, direction: "ascending" });
    }
  }

 

  clearChat() {
    this.props.clearChat();
    this.setState({clearConfirmOpen: false});
  }

  render() {
    let column = this.state.column;
    let direction = this.state.direction;
    let chatMessages = this.props.chatMessages.slice();
    chatMessages.sort(function (msga, msgb) {
      let a = msga[column];
      let b = msgb[column];
      let value;
      if (a > b) {
        value = 1;
      } else {
        value = -1;
      }
      if (direction === "descending") {
        value = -1 * value;
      }
      return value;
    });
    let that = this;
    let messageRows = chatMessages.map(that.createMessageRow.bind(that));

    let selectOptions = this.props.currentUsers.map(function (cu) {
      return {
        key: "so" + cu.chatId,
        value: cu.chatId,
        text: `${cu.chatId}`,
      };
    });
    selectOptions.push({ key: "all", value: "all", text: "all" });

    let chatRender = null;
    if (this.state.selected !== null) {
      chatRender = (
        <div>
          <h2>Rendered Message</h2>
          <Segment style={{ maxHeight: "50vh", overflow: "auto" }}>
            <MarkdownRender
              md={chatMessages[this.state.selected].content}
              images={[]}
            />
          </Segment>
        </div>
      );
    }

    let disableChatMsg = "";
    if (!this.props.enabled && this.props.userInfo.role !== "admin") {
      disableChatMsg = (
        <Message negative>
          You can only chat with the instructor right now
        </Message>
      );
    }
    if (this.props.ws.readyState === this.props.ws.CLOSED) { // WebSocket CLOSED
      disableChatMsg = (
        <Message negative>
          The communications link is down or the classroom has been closed.
        </Message>
      );
    }

    return (
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            <h2>Send Message</h2>
            <Segment>
              <Form>
                {disableChatMsg}
                <Form.Group inline>
                  <label>To:</label>
                  <Select
                    value={this.state.to}
                    options={selectOptions}
                    onChange={this.toChange.bind(this)}
                  />
                  <Button
                    onClick={this.send.bind(this)}
                    disabled={this.state.sendMessage.length === 0}
                  >
                    Send Message
                  </Button>
                </Form.Group>
                <TextArea
                  onChange={this.onInput.bind(this)}
                  value={this.state.sendMessage}
                />
              </Form>
            </Segment>
            <Segment style={{ maxHeight: "50vh", overflow: "auto" }}>
              <MarkdownRender md={this.state.sendMessage} images={[]} />
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <h2>Previous Messages </h2>
            <Form size="small">
              <Form.Group>
                <Form.Button
                  label="Clear All Chat Messages"
                  onClick={() => this.setState({ clearConfirmOpen: true })}
                >Clear</Form.Button>
                <Confirm
                  open={this.state.clearConfirmOpen}
                  onCancel={() => this.setState({ clearConfirmOpen: false })}
                  onConfirm={this.clearChat.bind(this)}
                />
                <Form.Button
                  label="Chat Messages Download"
                  onClick={()=>chatHistDownload(this.props.chatMessages, this.props.course)}
                >
                  Save
                </Form.Button>
              </Form.Group>
            </Form>
            <Segment style={{ maxHeight: "50vh", overflow: "auto" }}>
              {" "}
              <Table celled selectable sortable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      onClick={this.handleSort.bind(this, "datetime")}
                      sorted={column === "datetime" ? direction : null}
                    >
                      Time
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={this.handleSort.bind(this, "from")}
                      sorted={column === "from" ? direction : null}
                    >
                      From
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      onClick={this.handleSort.bind(this, "to")}
                      sorted={column === "to" ? direction : null}
                    >
                      To
                    </Table.HeaderCell>
                    <Table.HeaderCell>Content</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{messageRows}</Table.Body>
              </Table>
            </Segment>
            {chatRender}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Chat;
