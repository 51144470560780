import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Menu,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import offeredCourses from "../Server/currentCourses.json";

/**
 * User Management component works with website for approving registrants, 
 * deleting registrants, deleting users, etc...
 * 
 * Typical registrant/ user Data:
 * {
    "userId": "qd7373",
    "first": "Greg",
    "last": "Bernstein",
    "email": "greg.bernstein@csueastbay.edu",
    "courses": ["CS351", "CS651", "CS671"],
    "passHash": "password hash"
}
 */
class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      statusFilter: "all",
      courseFilter: "all",
      globalAction: "none",
    };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    let that = this;
    fetch("/users")
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          let info = `Status code: ${response.status}, ${response.statusText}`;
          console.log(response);
          return Promise.reject(info);
        }
      })
      .then(function (data) {
        // console.log(data);
        data.forEach(function (user) {
          user.action = "none";
        });
        that.setState({ users: data });
      })
      .catch(function (msg) {
        console.log("Something bad: " + msg);
      });
  }

  statusFiltChange(event, data) {
    this.setState({ statusFilter: data.value });
  }

  courseFiltChange(event, data) {
    this.setState({ courseFilter: data.value });
  }

  filterStatus(user) {
    let statusFilter = true;
    if (this.state.statusFilter !== "all") {
      statusFilter = user.status === this.state.statusFilter;
    }
    let courseFilter = true;
    if (this.state.courseFilter !== "all") {
      courseFilter = user.courses.includes(this.state.courseFilter);
    }
    return statusFilter && courseFilter;
  }
  globalActionChange(event, data) {
    this.setState({ globalAction: data.value });
    let that = this;
    let updatedUsers = this.state.users.map(function (user) {
      if (that.filterStatus(user)) {
        user.action = data.value;
      }
      return user;
    });
    this.setState({ users: updatedUsers });
  }

  actionChange(userId, event, data) {
    let userUpdate = this.state.users.map(function (user) {
      if (user.userId === userId) {
        return Object.assign({}, user, { action: data.value });
      } else {
        return user;
      }
    });
    this.setState({ users: userUpdate });
  }

  async applyActions() {
    console.log("About to apply actions!");
    let that = this;
    let updatedUsers = this.state.users.map(function (user) {
      switch (user.action) {
        case "approve":
          if (user.status === "approved") {
            return user;
          } else {
            return that.updateUser(user);
          }
        case "suspend":
          if (user.status === "suspended") {
            return user;
          } else {
            return that.updateUser(user);
          }
        case "delete":
          return that.deleteUser(user);
        default:
          return user;
      }
    });
    updatedUsers = await Promise.all(updatedUsers);
    console.log(updatedUsers);
    updatedUsers = updatedUsers.filter((u) => u !== undefined);
    this.setState({ users: updatedUsers });
  }

  async updateUser(user) {
    return fetch(`/users/action/${user.userId}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        action: user.action,
      }),
    })
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        if (response.status === 200) {
          let temp = response.json();
          console.log(temp);
          return temp;
        } else {
          console.log("Put request to update user returned error code.");
          return user;
        }
      })
      .then(function (user) {
        user.action = "none";
        console.log("Finishing updateUser function");
        console.log(user);
        return user;
      });
  }

  async deleteUser(user) {
    return fetch(`/users/${user.userId}`, {
      method: "DELETE",
    }).then(function (response) {
      console.log(
        "Request status code: ",
        response.statusText,
        response.status,
        response.type
      );
      if (response.status === 200) {
        return undefined; // user has be delete so will return undefined
      } else {
        return user;
      }
    });
  }

  render() {
    let that = this;
    let filteredUsers = this.state.users.filter(function (u) {
      if (that.state.statusFilter === "all") {
        return true;
      }
      return u.status === that.state.statusFilter;
    });
    filteredUsers = filteredUsers.filter(function (u) {
      if (that.state.courseFilter === "all") {
        return true;
      }
      return u.courses.includes(that.state.courseFilter);
    });
    const statusFilter = [
      { key: "all", text: "All", value: "all" },
      { key: "pending", text: "Pending", value: "pending" },
      { key: "approved", text: "Approved", value: "approved" },
      { key: "suspended", text: "Suspended", value: "suspended" },
    ];

    const actionOptions = [
      { key: "none", text: "None", value: "none" },
      { key: "approve", text: "Approve", value: "approve" },
      { key: "suspend", text: "Suspend", value: "suspend" },
      { key: "delete", text: "Delete", value: "delete" },
    ];

    const courseFilter = [{ key: "all", text: "All", value: "all" }];
    offeredCourses.forEach(function (course) {
      courseFilter.push({ key: course, text: course, value: course });
    });
    const headingSelStyle = null; //{ display: "flex", flexDirection: "column" };
    let rows = filteredUsers.map(function (u, i) {
      let positive = u.action === "approve" && u.status !== "approved";
      let negative = u.action === "delete";
      let warning = u.action === "suspend" && u.status !== "suspended";
      return (
        <Table.Row
          key={u.userId}
          positive={positive}
          negative={negative}
          warning={warning}
        >
          <Table.Cell>{u.userId}</Table.Cell>
          <Table.Cell>{u.first}</Table.Cell>
          <Table.Cell>{u.last}</Table.Cell>
          <Table.Cell>{u.status}</Table.Cell>
          <Table.Cell>{u.courses.join(", ")}</Table.Cell>
          <Table.Cell>
            <Form.Select
              options={actionOptions}
              value={u.action}
              onChange={that.actionChange.bind(that, u.userId)}
              fluid
            />
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Segment style={{ minHeight: "80vh" }}>
        <Menu secondary>
          <Menu.Item>
            <p>Showing {filteredUsers.length} users</p>
          </Menu.Item>
          <Menu.Item>
            <Button onClick={this.applyActions.bind(this)}>
              Apply Actions
            </Button>
          </Menu.Item>
        </Menu>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>UserId</Table.HeaderCell>
              <Table.HeaderCell width={4}>First</Table.HeaderCell>
              <Table.HeaderCell width={4}>Last</Table.HeaderCell>
              <Table.HeaderCell style={headingSelStyle} width={2}>
                <Form.Select
                  label="Status"
                  options={statusFilter}
                  value={this.state.statusFilter}
                  onChange={this.statusFiltChange.bind(this)}
                  fluid
                />
              </Table.HeaderCell>
              <Table.HeaderCell style={headingSelStyle} width={2}>
                <Form.Select
                  label="Courses"
                  options={courseFilter}
                  value={this.state.courseFilter}
                  onChange={this.courseFiltChange.bind(this)}
                  fluid
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                <Form.Select
                  label="Action"
                  options={actionOptions}
                  value={this.state.globalAction}
                  onChange={this.globalActionChange.bind(this)}
                  fluid
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default ManageUsers;
