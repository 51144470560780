import React from "react";
import {
  Confirm,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Segment,
  Select,
  Table,
} from "semantic-ui-react";

import offeredCourses from "../Server/currentCourses.json";

/**
 * For showing current status of course and for making changes to the course and
 * individual users. Makes HTTP requests directly to the website.
 * Gets updates via properties that are tied to classroom status messages.
 *
 */
class AdminCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCourse: props.course,
      registrationEnabled: props.registrationEnabled,
      courseChat: props.courseChat,
      users: props.currentUsers,
      troubleMakers: [],
      openCloseCourseConfirm: false,
      openRemoveConfirm: false,
      openClearAllConfirm: false,
      showId: false,
    };
  }

  componentDidUpdate(prevProps) {
    // We can get updates to props via the receipt of status messages
    // which we then apply to the state
    // React may have other reasons for updating so you always do checks like these
    if (this.props.currentUsers !== prevProps.currentUsers) {
      console.log("Admin course currentUsers changed in did update");
      this.setState({ users: this.props.currentUsers });
    }
    if (
      this.props.course !== prevProps.course ||
      this.props.courseChat !== prevProps.courseChat
    ) {
      console.log("Admin course other props changed in did update");
      this.setState({
        currentCourse: this.props.course,
        courseChat: this.props.courseChat,
        openResponseConfirm: false,
      });
    }
  }

  setCourseClick() {
    let that = this;
    fetch("/course", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        currentCourse: that.state.currentCourse
      }),
    })
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        return response.json();
      })
      .then(function (courseInfo) {
        // We don't do anything with the returned info
        // since we want to hear it from a WebSockets message
        // that goes to all users.
        // console.log(courseInfo);
      });
  }

  setChatClick() {
    let that = this;
    fetch("/chat", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        chatEnabled: that.state.courseChat,
        usersChat: that.state.users,
      }),
    })
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        return response.json();
      })
      .then(function (chatInfo) {
        // We don't do anything with the returned info
        // since we want to hear it from a WebSockets message
        // that goes to all users.
        console.log(chatInfo);
      });
  }

  currentCourseInput(event) {
    this.setState({ currentCourse: event.target.value });
  }

  regEnabledChange(event, data) {
    this.setState({ registrationEnabled: data.checked });
    // Send message to server directly
    let that = this;
    fetch("/registrationControl", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        registrationEnabled: data.checked,
      }),
    })
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        return response.json();
      })
      .then(function (courseInfo) {});
  }

  showIdChange(event, data) {
    this.setState({ showId: data.checked });
  }

  chatEnableChange(event, data) {
    this.setState({ courseChat: data.checked });
  }

  indChatChange(index, event, data) {
    let tempUsers = this.state.users.map(function (u, i) {
      if (index === i) {
        return Object.assign({}, u, { chatEnabled: data.checked });
      } else {
        return u;
      }
    });
    this.setState({ users: tempUsers });
  }

  removeChange(event, data) {
    console.log("removeChange called");
    console.log(data);
    this.setState({ troubleMakers: data.value });
  }

  closeCourse() {
    console.log("closing course now!");
    this.props.clearCourse();
    fetch("/closeCourse")
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        return response.json();
      })
      .then(function (msg) {
        // We don't do anything with the returned info
        console.log(msg);
      });
    this.setState({ openCloseCourseConfirm: false });
  }

  removeStudents() {
    let that = this;
    this.setState({ openRemoveConfirm: false });
    fetch("/removeStudents", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        toRemove: that.state.troubleMakers,
      }),
    })
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        return response.json();
      })
      .then(function (msg) {
        // We don't do anything with the returned info
        // since we want to hear it from a WebSockets message
        // that goes to all users.
        console.log(msg);
      });
  }

  courseChange(event, data) {
    this.setState({ currentCourse: data.value });
  }

  // Renders component based on current state and props
  render() {
    let selectOptions = [];
    this.state.users.forEach(function (user) {
      if (user.role === "student") {
        selectOptions.push({
          key: user.chatId,
          value: user.chatId,
          text: user.chatId,
        });
      }
    });

    let that = this;
    const positive = <Icon color="green" name="check" size="large" />;
    const negative = <Icon color="red" name="ban" size="large" />;
    let rows = this.state.users.map(function (u, i) {
      let idEl = u.userId; // Show id
      if (!that.state.showId) {
        // Otherwise show an indicator that there is an id or not
        if (!idEl) {
          idEl = negative;
        } else {
          idEl = positive;
        }
      }
      return (
        <Table.Row
          key={"u" + i}
          warning={u.chatEnabled !== that.state.users[i].chatEnabled}
        >
          <Table.Cell>{i + 1}</Table.Cell>
          <Table.Cell style={{ wordWrap: "anywhere" }}>{u.chatId}</Table.Cell>
          <Table.Cell>{idEl}</Table.Cell>
          <Table.Cell>{u.role}</Table.Cell>
          <Table.Cell>
            <Checkbox
              checked={u.chatEnabled}
              onChange={that.indChatChange.bind(that, i)}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
    const courseOptions = [{ key: "none", text: "None", value: "None" }];
    offeredCourses.forEach(function (course) {
      courseOptions.push({ key: course, text: course, value: course });
    });
    let regUsers = [];
    if (this.props.registeredUsers) {
      regUsers = this.props.registeredUsers.filter(function (u) {
        return u.courses.includes(that.props.course);
      });
    }
    return (
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            <h2>Current Course Info</h2>
            <Segment>
              <p>
                Course name: {this.props.course}, Chat Enabled:{" "}
                {this.props.courseChat.toString()}, Registration Enabled:{" "}
                {this.props.registrationEnabled.toString()}
              </p>
              <p>
                Current users: {this.state.users.length}, Registered users:{" "}
                {regUsers.length}, Message link: {this.props.sockStatus}
              </p>
              <Form.Checkbox
                label="Enable Registration"
                checked={this.state.registrationEnabled}
                onChange={this.regEnabledChange.bind(this)}
                error={
                  this.state.registrationEnabled !==
                  this.props.registrationEnabled
                }
              />
            </Segment>
            <Header as="h2" color="blue">
              Setup Course
            </Header>
            <Segment>
              <Form>
                <Form.Group inline widths="equal">
                  <Form.Select
                    label="Course"
                    options={courseOptions}
                    value={this.state.currentCourse}
                    onChange={this.courseChange.bind(this)}
                    error={this.state.currentCourse !== this.props.course}
                    disabled={this.props.course !== null}
                  />
                  <Form.Button
                    onClick={this.setCourseClick.bind(this)}
                    size="large"
                    color="blue"
                    disabled={this.props.course !== null}
                  >
                    Open Course
                  </Form.Button>
                  <Form.Button
                    size="large"
                    color="red"
                    onClick={() =>
                      this.setState({ openCloseCourseConfirm: true })
                    }
                    disabled={this.props.course === null}
                  >
                    Close Course
                  </Form.Button>
                  <Confirm
                    header={`Close ${this.props.course} and logout all students`}
                    open={this.state.openCloseCourseConfirm}
                    onCancel={() =>
                      this.setState({ openCloseCourseConfirm: false })
                    }
                    onConfirm={this.closeCourse.bind(this)}
                  />
                </Form.Group>
              </Form>
            </Segment>

            <Header as="h2" color="red">
              Danger!!!
            </Header>
            <Segment>
              <Form>
                <Form.Group inline>
                  <Form.Select
                    placeholder="Trouble makers"
                    options={selectOptions}
                    multiple
                    onChange={this.removeChange.bind(this)}
                    label="Remove Students"
                    inline
                  />
                  <Form.Button
                    color="orange"
                    onClick={() => this.setState({ openRemoveConfirm: true })}
                  >
                    Remove
                  </Form.Button>
                  <Confirm
                    open={this.state.openRemoveConfirm}
                    header="Remove these students from the classroom"
                    onCancel={() => this.setState({ openRemoveConfirm: false })}
                    onConfirm={this.removeStudents.bind(this)}
                  />
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <h2>Chat Control</h2>
            <Form>
              <Form.Group inline>
                <Form.Checkbox
                  error={this.state.courseChat !== this.props.courseChat}
                  checked={this.state.courseChat}
                  onChange={this.chatEnableChange.bind(this)}
                  label="Enable Chat for All"
                />

                <Form.Button onClick={this.setChatClick.bind(this)}>
                  Set Chat
                </Form.Button>
              </Form.Group>
            </Form>
            <Segment style={{ maxHeight: "80vh", overflow: "auto" }}>
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    {/* Semantic UI based on 16 "column widths" */}
                    <Table.HeaderCell width={1}>#</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      <Checkbox
                        checked={this.state.showId}
                        onChange={this.showIdChange.bind(this)}
                        label="User Id"
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>Role</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Enabled</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{rows}</Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default AdminCourse;
