import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";

/**
 * Login component makes fetch call to website with credentials.
 * If login is successful will use property callback to update
 * user chatId and role. Otherwise will show an error message.
 */
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userId: "", password: "",  errorMsg: null };
  }

  loginClick() {
    let that = this;
    console.log("Button pressed");
    fetch("/login", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: that.state.userId,
        password: that.state.password,
      }),
    })
      .then(function (response) {
        console.log(
          "Request status code: ",
          response.statusText,
          response.status,
          response.type
        );
        return response.json();
      })
      .then(function (msg) {
        if (msg.error) {
          // Bad login
          that.props.setUser({ chatId: "unknown", role: "guest" });
          that.setState({ errorMsg: msg.message });
          console.log(msg);
        } else {
          // Good login
          that.props.setUser(msg);
        }
      });
  }

  userIdInput(event) {
    this.setState({ userId: event.target.value, errorMsg: null });
  }

  passInput(event) {
    this.setState({ password: event.target.value, errorMsg: null });
  }

  enterKey(event) {
    const keyName = event.key;
    if (keyName === "Enter") {
      let that = this;
      console.log("Enter pressed");
      this.loginClick();
    }
  }
  // Renders component based on current state and props
  render() {
    let errorMessage = null;
    if (this.state.errorMsg) {
      errorMessage = (
        <Message warning>
          <Message.Header>Login Problem:</Message.Header>
          {this.state.errorMsg}
        </Message>
      );
    }
    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="teal" textAlign="center">
            Log-in to{" "}
            <abbr title="Computer Science Oriented Audience Response System">
              CSOARS
            </abbr>
          </Header>
          <Form size="large">
            <Segment stacked>
            <Form.Input
                fluid
                placeholder="User Id"
                value={this.state.userId}
                onChange={this.userIdInput.bind(this)}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                value={this.state.password}
                onChange={this.passInput.bind(this)}
                onKeyPress={this.enterKey.bind(this)}
              />

              <Button
                color="teal"
                fluid
                size="large"
                onClick={this.loginClick.bind(this)}
              >
                Login
              </Button>
            </Segment>
          </Form>
          {errorMessage}
        </Grid.Column>
      </Grid>
    );
  }
}

export default Login;
