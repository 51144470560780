import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import pattern from "patternomaly";

/**
 * Displays statistics for responses to different question types.
 * Takes the question and a set of responses as properties.
 * @param {*} props
 */
function Statistics(props) {
  const letters = "abcdefghij";
  let answerType = props.question.content.meta.answerType;
  let numResponses = props.responses.length;
  let info = null;
  let chart = null;
  let pStyle = { marginBottom: "0.1em" };
  if (answerType === "singleChoice" || answerType === "manyChoices") {
    let numChoices = props.question.content.choices.length;
    let bins = [];
    for (let i = 0; i < numChoices; i++) {
      bins.push(0);
    }
    if (answerType === "singleChoice") {
      props.responses.forEach(function (r) {
        bins[r.response]++;
      });
    } else {
      // many choices case
      props.responses.forEach(function (r) {
        // Check for non-null response
        if (r.response) {
          r.response.forEach(function (choice, j) {
            if (choice) {
              bins[j]++;
            }
          });
        }
      });
    }
    let emStyle = { display: "inline-block", width: "3em" };
    let items = bins.map(function (bin, i) {
      return (
        <p key={"bin" + i} style={pStyle}>
          <strong>{letters[i]}.&nbsp;</strong>&nbsp;{" "}
          <em style={emStyle}>({bin})</em>
          {props.question.content.choices[i]}
        </p>
      );
    });
    info = <div>{items}</div>;
    let backgrounds = pattern.generate([
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#3ceae7",
      "#f9d713",
    ]);
    let data = {
      labels: bins.map(function (b, i) {
        return letters[i];
      }),
      datasets: [
        {
          label: "Responses",
          backgroundColor: backgrounds,
          // backgroundColor: "rgba(255,99,132,0.2)",
          // borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          // hoverBackgroundColor: "rgba(255,99,132,0.4)",
          // hoverBorderColor: "rgba(255,99,132,1)",
          data: bins,
        },
      ],
    };
    let options = {
      scales: {
        yAxes: [
          {
            ticks: { min: 0 },
            id: "first-y-axis",
            type: "linear",
          },
        ],
      },
      maintainAspectRatio: false,
    };
    chart = <Bar data={data} width={100} height={200} options={options} />;
  }

  return (
    <div>
      <p style={pStyle}>Number of responses: {numResponses}</p>
      {info}
      <div>{chart}</div>
    </div>
  );
}

export default Statistics;
