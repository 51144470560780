/* 
  File save functionality
  Based on https://stackoverflow.com/questions/13405129/javascript-create-and-save-file
*/

/**
 * Save the array of chatMessages into a JSON file that
 * the user downloads.
 * 
 * @param {Array} chatMessages 
 * @param {String} course 
 */
function chatHistDownload(chatMessages, course) {
  let file = new Blob([JSON.stringify(chatMessages)], {
    type: "application/json",
  });
  let a = document.createElement("a"),
    url = URL.createObjectURL(file);
  a.href = url;
  let curDT = new Date();
  let dtString = `${curDT.getFullYear()}_${
    curDT.getMonth() + 1
  }_${curDT.getDate()}T${curDT.getHours()}_${curDT.getMinutes()}`;
  a.download = `${course}_Chat_${dtString}.json`;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

/**
 * Saves the current response and response history into a JSON file for the
 * user to download.
 * 
 * @param {Object} question 
 * @param {Array} responses 
 * @param {Array} responseHistory 
 * @param {String} course 
 */
function responseHistDownload(question, responses, responseHistory, course) {
  // Create a response history item for the current question if any
  let histItem = null;
  if (question) {
    histItem = {
      question: question,
      responses: responses,
    };
  }
  let file = new Blob([JSON.stringify(responseHistory.concat(histItem))], {
    type: "application/json",
  });
  let a = document.createElement("a"),
    url = URL.createObjectURL(file);
  a.href = url;
  let curDT = new Date();
  let dtString = `${curDT.getFullYear()}_${
    curDT.getMonth() + 1
  }_${curDT.getDate()}T${curDT.getHours()}_${curDT.getMinutes()}`;
  a.download = `${course}_Responses${dtString}.json`;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

/**
 * Use to send an "empty" question to clear out an "open" question.
 * 
 * @param {WebSocket} ws 
 * @param {String} chatId 
 */
function clearQuestion(ws, chatId) {
  let dt = new Date();
  let message = {
    version: 1,
    type: "question",
    datetime: dt.toISOString(),
    to: "all",
    from: chatId,
    content: null,
  };
  ws.send(JSON.stringify(message));
}

export { chatHistDownload, responseHistDownload, clearQuestion };
